import React from "react";
import { Helmet } from "react-helmet";

const headline = "2023-2024 Eğitim Öğretim Yılı Akademik Takvimi";
const calendar = [
  "2023-2024  Eğitim–Öğretim Yılının Başlaması&&&4 Eylül 2023 Pazartesi",
  "Oryantasyon Çalışmaları&&&4 Eylül 2023 - 29 Eylül 2023",
  "Genel Veli Toplantısı&&&09 Eylül 2023 Cumartesi",
  "Cumhuriyet Bayramı Töreni&&&27 Ekim 2023 Cuma günü yapılacaktır.",
  "Atatürk’ü Anma Günü&&&10 Kasım 2023 Cuma",
  "1.Dönem Veli Toplantısı&&&6 Kasım – 6 Aralık 2023 Tarihleri Arasında Okulda Yüz yüze yapılacaktır.",
  "Yılbaşı Kutlama&&&28 Aralık 2023 Perşembe",
  "I.Dönem Karne Partisi&&&19 Ocak 2024 Cuma günü",
  "I.Dönem Gelişim Raporları&&&15-19 Ocak 2024 haftası paylaşılır.",
  "Sömestr Tatili&&&22 – 26 Ocak 2024 haftası okulumuz kapalıdır.",
  "II.Dönem Başlangıç&&&29 Ocak 2024 Pazartesi",
  "II. Dönem Veli Toplantısı&&&11 Mart - 29 Mart 2024 Tarihleri Arasında Yüz yüze yapılacaktır.",
  "Kodlama Dersi Açık Ders&&&27 Mart 2024 Çarşamba öğleden sonra Hazırlık Sınıfı Velileri katılımı ile gerçekleştirilecektir.",
  "Ramazan Bayramı Tatili&&&8 - 12 Nisan 2024 Okulumuz Kapalıdır.",
  "23 Nisan Ulusal Egemenlik ve Çocuk Bayramı&&&23 Nisan 2024 Salı Veli katılımı ile çocuk şenliği yapılacaktır.",
  "1 Mayıs Emek ve Dayanışma Günü&&&Resmi Tatil Dolayısı ile okulumuz kapalıdır.",
  "3-4-5 Yaşlar Portfolyo Sunum Etkinliği&&&4 Mayıs 2024 Cumartesi",
  "19 Mayıs Atatürk’ü Anma, Gençlik ve Spor Bayramı&&&17 Mayıs 2024 Cuma günü Kampüs Kreş – Well Clup İş birliği ile kutlanacaktır.",
  "Hazırlık Sınıfı Mezuniyet&&&8 Haziran 2024 Cumartesi Öğleden sonra yapılacaktır.",
  "II.Dönem Gelişim Raporları&&&10-14 Haziran 2023 Haftası yüklenecektir.",
  "II. Dönem Karne Partisi&&&14 Haziran 2024 Cuma günü",
  "Kurban Bayramı Tatili&&&15-16-17-18-19 Haziran 2024 Resmi Tatil dolayısı ile okulumuz kapalıdır.",
  "2023-2024 Eğitim-Öğretim Döneminin Son Günü&&&28 Haziran 2024 Cuma",
  "Yaz Tatili (Okulun kapalı olduğu dönem)&&&1 Temmuz – 12 Temmuz 2024",
  "15 Temmuz Demokrasi ve Millî Birlik Günü&&&Resmi Tatil Dolayısı ile okulumuz kapalıdır.",
  "Yaz Okulu 1. Kur (2 haftalık periyot)&&&16 Temmuz – 26 Temmuz 2024",
  "Yaz Okulu 2. Kur (2 haftalık periyot)&&&29 Temmuz – 9 Ağustos 2024",
  "Yaz Okulu 3. Kur (2 haftalık periyot)&&&12  Ağustos – 23 Ağustos 2024",
  "30 Ağustos Zafer Bayramı&&&Resmi Tatil Dolayısı ile okulumuz kapalıdır.",
  "2024-2025 Eğitim Öğretim Dönemi&&&2.09.2024 Pazartesi başlayacaktır.",
];

const page = [
  { pathName: "akademik-takvim", pageName: "Akademik Takvim" },
  function () {
    return (
      <div className="tableWithNoteWrap">
        <Helmet>
          <title>Kampüs Kreş - Akademik Takvim</title>
          <meta name="title" content="Kampüs Kreş - Akademik Takvim" />
          <meta property="og:title" content="Kampüs Kreş - Akademik Takvim" />
          <meta name="twitter:title" content="Kampüs Kreş - Akademik Takvim" />
        </Helmet>
        <table className="table">
          <tr>
            <th colSpan={2}>{headline}</th>
          </tr>
          {calendar.map((v) => {
            const args = v.split("&&&");

            return (
              <tr>
                <td>{args[0]}</td>
                <td>{args[1]}</td>
              </tr>
            );
          })}
        </table>
        <p>
          NOT: 26 – 29 Ağustos 2024 tarihleri arasında okulumuz kapalı
          olacaktır.
          <br />
          <br />
          NOT: Sağlık Bakanlığı’nın vereceği kararlar doğrultusunda akademik
          takvimde yer alan tarihler ve toplantıların online ya da yüz yüze olma
          durumu değişiklik gösterebilir.
        </p>
      </div>
    );
  },
];

export default page;
